.container {
	width: 100vw;
	max-width: 1600px;
	margin: auto;
	display: grid;
	padding: 0px 64px;
	grid-template-columns: repeat(12, 8.33%);
	grid-template-rows: repeat(10, 64px);
	row-gap: 8px;
	overflow: hidden;
}

.header {
	font-size: 109.66px;
	line-height: 109.66px;
	font-weight: 600;
	grid-row: 3 / span 2;
	grid-column: 2 / span 9;
}

.name {
	font-size: 25.89px;
	font-weight: 600;
}
.p {
	font-size: 16px;
	line-height: 24px;
	font-weight: 400;
	max-width: 550px;
	grid-row: 6 / span 3;
	grid-column: 5 / span 5;
}

@media screen and (max-width: 1200px) {
	.container {
		width: 100vw;
		display: grid;
		padding: 0px 64px;
		grid-template-columns: repeat(12, 8.33%);
		grid-template-rows: repeat(10, 64px);
		row-gap: 8px;
	}

	.header {
		font-size: 109.66px;
		line-height: 109.66px;
		font-weight: 600;
		grid-row: 3 / span 2;
		grid-column: 2 / span 11;
	}

	.name {
		font-size: 25.89px;
		font-weight: 600;
	}
	.p {
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
		max-width: 550px;
		grid-row: 6 / span 3;
		grid-column: 5 / span 7;
	}
}

@media screen and (max-width: 1200px) {
	.container {
		width: 100vw;
		display: grid;
		padding: 0px 32px;
		grid-template-columns: repeat(12, 8.33%);
		grid-template-rows: repeat(10, 48px);
		row-gap: 8px;
	}

	.header {
		font-size: 63.54px;
		line-height: 63.54px;
		font-weight: 600;
		grid-row: 3 / span 2;
		grid-column: 2 / span 11;
	}

	.name {
		font-size: 24.27px;
		font-weight: 600;
	}
	.p {
		font-size: 15px;
		line-height: 22px;
		font-weight: 400;
		max-width: 475px;
		grid-row: 5 / span 3;
		grid-column: 4 / span 9;
	}
}

@media screen and (max-width: 600px) {
	.container {
		width: 100vw;
		display: grid;
		padding: 0px 16px;
		grid-template-columns: 100%;
		grid-template-rows: repeat(14, 32px);
		row-gap: 4px;
	}

	.header {
		font-size: 59.3px;
		line-height: 59.3px;
		font-weight: 600;
		grid-row: 1 / span 2;
		grid-column: 1;
	}

	.name {
		font-size: 22.65px;
		font-weight: 600;
	}
	.p {
		font-size: 14px;
		line-height: 20px;
		font-weight: 400;
		max-width: 400px;
		grid-row: 6 / span 3;
		grid-column: 1;
	}
}
