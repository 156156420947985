.container {
	width: fit-content;
	position: relative;
	cursor: pointer;
	overflow: hidden;
	padding: 8px 0px;
}
.anchor {
	text-decoration: none;
	color: inherit;
}
.text {
	position: relative;
}
.line {
	position: relative;
	top: 2px;
	width: 100%;
	height: 2px;
}
