.container {
	width: 100vw;
	height: 100vh;
	background-color: #ffffff;
}

.svg {
	position: absolute;
	top: 40%;
	left: 50%;
	width: 200px;
	transform: translateX(-50%);
}

.leftLine {
	position: absolute;
	height: 10px;
	background-color: #000000;
	top: 45%;
	transform: translate(-54.5px, 64.5px);
}
