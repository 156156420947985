.background {
	width: 100vw;
	background-color: #000000;
	color: #000000;
}
.container {
	width: 100vw;
	max-width: 1600px;
	margin: auto;
	display: grid;
	padding: 0px 64px;
	grid-template-columns: repeat(12, 8.33%);
	grid-template-rows: repeat(9, 64px);
	row-gap: 8px;
}

.headerContainer {
	grid-row: 2 / span 2;
	grid-column: 2 / span 11;
}

.header {
	font-size: 109.66px;
	line-height: 109.66px;
	font-weight: 600;
	color: #ffffff;
}

.contactsContainer {
	grid-row: 6;
	grid-column: 2 / span 4;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.email {
	font-size: 25.89;
	font-weight: 600;
	color: #ffffff;
	cursor: pointer;
}

.linksContainer {
	display: flex;
	gap: 32px;
}
.link {
	font-size: 16;
	font-weight: 600;
	color: #ffffff;
}

@media screen and (max-width: 1200px) {
	.container {
		width: 100vw;
		display: grid;
		padding: 0px 64px;
		grid-template-columns: repeat(12, 8.33%);
		grid-template-rows: repeat(9, 64px);
		row-gap: 8px;
	}

	.headerContainer {
		grid-row: 2 / span 2;
		grid-column: 2 / span 11;
	}

	.header {
		font-size: 109.66px;
		line-height: 109.66px;
		font-weight: 600;
		color: #ffffff;
	}

	.contactsContainer {
		grid-row: 7;
		grid-column: 2 / span 4;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.email {
		font-size: 25.89;
		font-weight: 600;
		color: #ffffff;
	}

	.linksContainer {
		display: flex;
		gap: 32px;
	}
	.link {
		font-size: 16;
		font-weight: 600;
		color: #ffffff;
	}
}

@media screen and (max-width: 1000px) {
	.container {
		width: 100vw;
		display: grid;
		padding: 0px 32px;
		grid-template-columns: repeat(12, 8.33%);
		grid-template-rows: repeat(9, 48px);
		row-gap: 8px;
	}

	.headerContainer {
		grid-row: 2 / span 2;
		grid-column: 2 / span 11;
	}

	.header {
		font-size: 63.54px;
		line-height: 63.54px;
		font-weight: 600;
		color: #ffffff;
	}

	.contactsContainer {
		grid-row: 7;
		grid-column: 2 / span 5;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.email {
		font-size: 24.27px;
		font-weight: 600;
		color: #ffffff;
	}

	.linksContainer {
		display: flex;
		gap: 32px;
	}
	.link {
		font-size: 15;
		font-weight: 600;
		color: #ffffff;
	}
}

@media screen and (max-width: 600px) {
	.container {
		width: 100vw;
		display: grid;
		padding: 0px 16px;

		grid-template-columns: 100%;
		grid-template-rows: repeat(10, 32px);
		row-gap: 4px;
	}

	.headerContainer {
		grid-row: 2 / span 3;
		grid-column: 1;
	}

	.header {
		font-size: 59.3px;
		line-height: 59.3px;
		font-weight: 600;
		color: #ffffff;
	}

	.contactsContainer {
		grid-row: 8;
		grid-column: 1;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.email {
		font-size: 22.65;
		font-weight: 600;
		color: #ffffff;
	}

	.linksContainer {
		display: flex;
		gap: 32px;
	}
	.link {
		font-size: 14;
		font-weight: 600;
		color: #ffffff;
	}
}
