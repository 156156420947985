.container {
	width: 100vw;
	max-width: 1800px;
	display: grid;
	margin: auto;
	padding: 64px 64px 0px 64px;
	grid-template-columns: repeat(12, 8.33%);
	grid-template-rows: repeat(12, 64px);
	row-gap: 8px;
}

.name {
	font-size: 287.07px;
	line-height: 287.07px;
	font-weight: 600;
	transform: translate(-20px, -34px);
	grid-column: 1 / span 8;
	grid-row: 3 / span 3;
}

.title {
	font-size: 67.77px;
	line-height: 67.77px;
	font-weight: 400;
	transform: translate(-2px, 8px);
	grid-column: 1 / span 6;
	grid-row: 6;
}

.intro {
	font-size: 25.89px;
	line-height: 25.89px;
	font-weight: 400;
	grid-column: 8 / span 4;
	grid-row: 9;
}

.links {
	display: flex;
	grid-column: 8 / span 3;
	grid-row: 10;
	gap: 24px;
	align-self: center;
}
.link {
	font-size: 16px;
	line-height: 16px;
	font-weight: 600;
}

@media screen and (max-width: 1200px) {
	.container {
		width: 100vw;
		display: grid;
		padding: 64px 64px 0px 64px;
		grid-template-columns: repeat(12, 8.33%);
		grid-template-rows: repeat(12, 64px);
		row-gap: 8px;
	}

	.name {
		font-size: 287.07px;
		line-height: 287.07px;
		font-weight: 600;
		transform: translate(-20px, -34px);
		grid-column: 1 / span 8;
		grid-row: 3 / span 3;
	}

	.title {
		font-size: 67.77px;
		line-height: 67.77px;
		font-weight: 400;
		transform: translate(-2px, 8px);
		grid-column: 1 / span 8;
		grid-row: 6;
	}

	.intro {
		font-size: 25.89px;
		line-height: 25.89px;
		font-weight: 400;
		grid-column: 8 / span 5;
		grid-row: 9;
	}

	.links {
		display: flex;
		grid-column: 8 / span 4;
		grid-row: 10;
		gap: 24px;
		align-self: center;
	}
	.link {
		font-size: 16px;
		line-height: 16px;
		font-weight: 600;
	}
}

@media screen and (max-width: 1000px) {
	.container {
		width: 100vw;
		display: grid;
		padding: 32px 32px 0px 32px;
		grid-template-columns: repeat(12, 8.33%);
		grid-template-rows: repeat(12, 48px);
		row-gap: 8px;
	}

	.name {
		font-size: 166.34px;
		line-height: 166.34px;
		font-weight: 600;
		transform: translate(-12px, -34px);
		grid-column: 1 / span 8;
		grid-row: 4 / span 3;
	}

	.title {
		font-size: 63.54px;
		line-height: 63.54px;
		font-weight: 400;
		transform: translate(-2px, 8px);
		grid-column: 1 / span 11;
		grid-row: 6;
	}

	.intro {
		font-size: 24.27px;
		line-height: 24.27px;
		font-weight: 400;
		grid-column: 6 / span 7;
		grid-row: 9;
	}

	.links {
		display: flex;
		grid-column: 6 / span 7;
		grid-row: 10;
		gap: 24px;
		align-self: center;
	}
	.link {
		font-size: 15px;
		line-height: 15px;
		font-weight: 600;
	}
}

@media screen and (max-width: 600px) {
	.container {
		width: 100vw;
		display: grid;
		grid-template-rows: repeat(16, 32px);
		grid-template-columns: 100%;
		padding: 0px 16px;
		row-gap: 4px;
	}

	.name {
		font-size: 95.95px;
		line-height: 75px;
		font-weight: 600;
		grid-row: 5 / span 2;
		grid-column: 1;
		transform: translate(-4px, 0px);
		align-self: flex-end;
	}

	.title {
		font-size: 36.65px;
		line-height: 36.65px;
		font-weight: 400;
		grid-row: 7;
		grid-column: 1;
		transform: translate(0px, 0px);
	}

	.intro {
		font-size: 22.65px;
		line-height: 22.65px;
		font-weight: 400;
		grid-row: 10 / span 2;
		grid-column: 1;
	}

	.links {
		display: flex;
		gap: 20px;
		align-self: center;
		grid-row: 12;
		grid-column: 1;
	}
	.link {
		font-size: 14px;
		line-height: 14px;
		font-weight: 600;
	}
}
