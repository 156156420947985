.container {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	padding: 8px 16px;
	background-color: #0d0d0d;
	border-radius: 8px;
	margin: 16px;
	width: fit-content;
	z-index: 100;
	box-shadow: 5px 5px 16px 0px rgba(0, 0, 0, 0.15);
}

.text {
	font-size: 16px;
	font-weight: 400;
	color: #ffffff;
}

@media screen and (max-width: 1200px) {
	.container {
		padding: 7.5px 15px;
	}
	.text {
		font-size: 15px;
	}
}

@media screen and (max-width: 600px) {
	.container {
		padding: 7px 14px;
	}
	.text {
		font-size: 14px;
	}
}
